
































import { Component, Emit, Prop, Ref, Vue } from "vue-property-decorator";
import { mdiClose } from "@mdi/js";

// Components
import Editor from "./Editor.vue";

// Types
import { Entry, EntryForm } from "@typings/entry";

@Component({
  components: {
    Editor,
  },
})
export default class EditorDialog extends Vue {
  /** Journal entry to update */
  @Prop({ default: null })
  entry!: Entry | null;
  /** Save handler (since child handles form) */
  @Prop({ required: true })
  onSave!: (values: EntryForm) => void;
  /** Dialog title */
  @Prop({ required: true })
  title!: string;
  /** Whether the dialog is shown */
  @Prop({ required: true })
  value!: boolean;

  // NOTE: Use ref to prevent closing form with changes
  @Ref()
  readonly editorRef!: Editor;

  isCancelDialogShown = false;
  icons = { mdiClose };

  /**
   * Prompt the user to cancel the editor changes
   */
  cancelPrompt(): void {
    const { changed } = this.editorRef.entryForm.flags;
    if (changed) {
      this.isCancelDialogShown = true;
      return;
    }

    this.cancel();
  }

  /**
   * Cancel the editor changes
   */
  @Emit("cancel")
  cancel(): void {
    this.editorRef.entryForm.reset();
  }
}
