










































































































import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import dayjs from "dayjs";
import { createForm } from "@kendallroth/vue-simple-forms";
import {
  mdiCalendar,
  mdiCheck,
  mdiClose,
  mdiEmoticonOutline,
  mdiStar,
  mdiStarOutline,
} from "@mdi/js";

// Components
import EditorIconSelector from "./EditorIconSelector.vue";

// Utilities
import { DATE_FORMAT, DATE_FORMAT_READABLE } from "@/constants";
import { moodIcons, moodIconList } from "./helpers";

// Types
import { EditorIconSelectorIcon } from "@typings/editor";
import { Entry, EntryForm } from "@typings/entry";

const today = dayjs().format(DATE_FORMAT);

const editorFormFields: EntryForm = {
  date: today,
  highlight: false,
  mood: null,
  text: "",
};

@Component({
  components: {
    EditorIconSelector,
  },
})
export default class Editor extends Vue {
  /** Journal entry */
  @Prop({ default: null })
  entry!: Entry | null;
  /** Save handler (since child handles form) */
  @Prop({ required: true })
  onSave!: (values: EntryForm) => void;

  /** Update form when parent jornal entry changes (since dialogs do not mount/unmount) */
  @Watch("entry", { deep: true, immediate: true })
  onEntryChanged(newEntry: Entry | null): void {
    if (!newEntry) {
      this.entryForm.setInitial({ ...editorFormFields });
      return;
    }

    this.entryForm.setInitial({
      date: dayjs(newEntry.date).format(DATE_FORMAT),
      highlight: newEntry.highlight,
      mood: newEntry.mood,
      text: newEntry.text,
    });
  }

  areEmoticonsShown = false;
  isDatePickerShown = false;
  isResetDialogShown = false;

  entryForm = createForm({ ...editorFormFields });

  moodIcons = moodIconList;
  today = today;

  icons = {
    mdiCalendar,
    mdiCheck,
    mdiClose,
    mdiEmoticon: mdiEmoticonOutline,
    mdiStar,
    mdiStarOutline,
  };

  /** Whether the form can be submitted */
  get canSubmit(): boolean {
    const { text } = this.entryForm.getValues() as EntryForm;

    return Boolean(text?.trim());
  }

  /** Selected entry mood */
  get selectedEntryMood(): EditorIconSelectorIcon | null {
    const { mood } = this.entryForm.getValues() as EntryForm;
    if (!mood) return null;

    return moodIcons[mood] ?? null;
  }

  /** Formatted journal entry date */
  get entryDateFormatted(): string {
    const { date } = this.entryForm.getValues() as EntryForm;

    return dayjs(date).format(DATE_FORMAT_READABLE);
  }

  /**
   * Toggle whether the day was a highlight
   */
  toggleHighlight(): void {
    this.entryForm.setValues({
      highlight: !this.entryForm.fields.highlight,
    });
  }

  /**
   * Submit the editor entry
   */
  async submit(): Promise<void> {
    const { changed } = this.entryForm.flags;
    if (!changed) return;

    const values = this.entryForm.getValues() as EntryForm;
    if (!values.text) return;

    this.entryForm.setSubmitting(true);

    try {
      await this.onSave(values);
    } catch (e) {
      this.entryForm.setSubmitting(false);
      return;
    }

    this.entryForm.setSubmitting(false);
    this.entryForm.reset();
  }

  /**
   * Reset the editor
   */
  resetEntry(): void {
    this.entryForm.reset();
  }
}
