


















import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { mdiMinusCircle } from "@mdi/js";

// Types
import { EditorIconSelectorIcon } from "@typings/editor";

@Component
export default class EditorIconSelector extends Vue {
  /** List of icons */
  @Prop({ required: true })
  icons!: EditorIconSelectorIcon[];

  /** Selected icon */
  @Prop({ required: true })
  value!: string;

  iconPaths = { mdiMinusCircle };

  /**
   * Remove the icon selection
   * @returns Cleared selection
   */
  @Emit("clear")
  @Emit("input")
  clear(): null {
    return null;
  }

  /**
   * Select an icon
   * @param   icon Selected icon
   * @returns Selected icon
   */
  @Emit("select")
  @Emit("input")
  select(icon: string): string {
    return icon;
  }
}
