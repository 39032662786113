















































































import { Component, Mixins } from "vue-property-decorator";
import dayjs from "dayjs";
import { mdiCommentPlus } from "@mdi/js";
import { v4 as uuidv4 } from "uuid";

// Components
import Editor, { EditorDialog } from "@components/editor";
import { JournalEntry } from "@components";

// Utilities
import { Errors, ErrorMap } from "@/errors";
import { AuthMixin } from "@mixins";
import { EntryService } from "@services";
import { EntryModule, UserModule } from "@store/modules";

// Types
import { Entry, EntryForm } from "@typings/entry";

@Component({
  components: {
    Editor,
    EditorDialog,
    JournalEntry,
  },
})
export default class Timeline extends Mixins(AuthMixin) {
  isAddDialogShown = false;
  /** Entry selected for deletion */
  deletedEntry: Entry | null = null;
  deletingEntry = false;
  /** Entry selected for updating */
  updatedEntry: Entry | null = null;

  icons = { mdiCommentPlus };

  /** Journal entries */
  get entries(): Entry[] {
    return EntryModule.entries;
  }

  /** Whether entries are loading */
  get loadingEntries(): boolean {
    return EntryModule.loading;
  }

  /** Whether the journaling prompt should be shown (for empty timelines) */
  get hasEntries(): boolean {
    return this.entries.length > 0;
  }

  /**
   * Add a journal entry
   *
   * @param values New entry values
   */
  async addEntry(values: EntryForm): Promise<void> {
    // NOTE: Submission state is handled by child Editor
    try {
      if (!this.user) throw new Error(Errors.AUTH__NO_AUTH);

      const entry: Entry = {
        id: uuidv4(),
        createdAt: new Date(),
        date: dayjs(values.date).toDate(),
        highlight: values.highlight,
        mood: values.mood ?? null,
        text: values.text,
        updatedAt: null,
        userId: this.user.uid,
      };

      await EntryService.addEntry(entry);

      EntryModule.addEntry(entry);
      UserModule.addEntry();

      this.isAddDialogShown = false;
      this.$notify("Journal entry added");
    } catch (e) {
      this.$notifyError(ErrorMap.ENTRY__ADD_FAILED);

      // NOTE: Error is handled by child Editor (this is a callback)
      throw e;
    }
  }

  /**
   * Cancel adding an entry
   */
  addEntryCancel(): void {
    this.isAddDialogShown = false;
  }

  /**
   * Prompt the user to add an entry
   */
  addEntryPrompt(): void {
    this.isAddDialogShown = true;
  }

  /**
   * Cancel deleting an entry
   */
  deleteEntryCancel(): void {
    this.deletedEntry = null;
  }

  /**
   * Delete an entry after confirmation
   */
  async deleteEntryConfirm(): Promise<void> {
    try {
      if (!this.user) throw new Error(Errors.AUTH__NO_AUTH);
      if (!this.deletedEntry) throw new Error(Errors.ENTRY__DELETE_FAILED);

      this.deletingEntry = true;

      await EntryService.deleteEntry(this.deletedEntry);

      EntryModule.deleteEntry(this.deletedEntry);
      UserModule.deleteEntry();
    } catch (e) {
      this.$notifyError(ErrorMap.ENTRY__DELETE_FAILED);
      this.deletedEntry = null;
      this.deletingEntry = false;
      return;
    }

    this.deletedEntry = null;
    this.deletingEntry = false;
    this.$notify("Journal entry removed");
  }

  /**
   * Prompt the user to confirm deleting an entry
   *
   * @param entry Entry pending deletion
   */
  deleteEntryPrompt(entry: Entry): void {
    this.deletedEntry = entry;
  }

  /**
   * Update a journal entry
   *
   * @param values Updated entry values
   */
  async updateEntry(values: EntryForm): Promise<void> {
    // NOTE: Submission state is handled by child Editor
    try {
      if (!this.updatedEntry) throw new Error(Errors.ENTRY__UPDATE_FAILED);
      if (!this.user || this.user.uid !== this.updatedEntry.userId) {
        throw new Error(Errors.AUTH__NO_AUTH);
      }

      const { createdAt, id, userId } = this.updatedEntry;
      const entry: Entry = {
        ...values,
        date: dayjs(values.date).toDate(),
        createdAt,
        id,
        updatedAt: new Date(),
        userId,
      };

      await EntryService.updateEntry(entry);

      EntryModule.updateEntry(entry);

      this.updatedEntry = null;
      this.$notify("Journal entry updated");
    } catch (e) {
      this.$notifyError(ErrorMap.ENTRY__UPDATE_FAILED);

      // NOTE: Error is handled by child Editor (this is a callback)
      throw e;
    }
  }

  /**
   * Cancel updating an entry
   */
  updateEntryCancel(): void {
    this.updatedEntry = null;
  }

  /**
   * Prompt the user to edit an entry
   *
   * @param entry Entry pending deletion
   */
  updateEntryPrompt(entry: Entry): void {
    this.updatedEntry = entry;
  }
}
